import React, { useState, useEffect } from "react";

// eslint-disable-next-line react/prop-types
const TimeAgo = ({ date }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const initialDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate - initialDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Approximate

    if (months > 0) {
      if (days % 30 !== 0) {
        setTimeAgo(`Il y a ${months} mois et ${days % 30} jours`);
      } else {
        setTimeAgo(`Il y a ${months} ${months === 1 ? "mois" : "mois"}`);
      }
    } else if (weeks > 0) {
      if (days % 7 !== 0) {
        setTimeAgo(`Il y a ${weeks} semaines et ${days % 7} jours`);
      } else {
        setTimeAgo(`Il y a ${weeks} ${weeks === 1 ? "semaine" : "semaines"}`);
      }
    } else if (days > 0) {
      setTimeAgo(`Il y a ${days} ${days === 1 ? "jour" : "jours"}`);
    } else {
      setTimeAgo("Aujourd'hui");
    }
  }, [date]);

  return <div>{timeAgo}</div>;
};

export default TimeAgo;
