/* eslint-disable react/no-unescaped-entities */
// @mui material components
import { Adjust, Looks3, LooksOne, LooksTwo } from "@mui/icons-material";

import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

//Image
import Material from "assets/images/offers/materiels.svg";
import Security from "assets/images/offers/Security.svg";
import NewTechnology from "assets/images/offers/newTechnology.svg";

//data
import dataOffers from "./data_offers";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { Fade } from "react-awesome-reveal";

function AssistanceInfo() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12, lg: 6 }}>
      <Container id="it_component">
        <Fade cascade damping={0.1}>
          <Typography variant="h1" gutterBottom style={{ color: "#900C3F" }}>
            {dataOffers.assistance_info.titre}
          </Typography>
          <Typography variant="body2" paragraph>
            {dataOffers.assistance_info.description}
          </Typography>
        </Fade>
      </Container>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={7} style={{ padding: "0.4rem" }}>
            <Fade>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <LooksOne fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Configuration des matériel et des logiciels" />
                </ListItemButton>
              </ListItem>
            </Fade>
            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Fade cascade>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Adjust />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Configuration Des Matériels :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {dataOffers.assistance_info.module1.description1}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Adjust />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Configuration Des Logiciels :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {dataOffers.assistance_info.module1.description2}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Fade>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <MKBox
                component="img"
                src={Material}
                alt="material"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="left">
              <MKBox
                component="img"
                src={Security}
                alt="Security"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Fade cascade damping={0.3}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <LooksTwo fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Sécurité des données" />
                </ListItemButton>
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem style={{ marginBottom: "8px" }}>
                    <MKTypography variant="body2" color="text">
                      {dataOffers.assistance_info.module2.description1.liste1}
                    </MKTypography>
                  </ListItem>
                  <Divider></Divider>
                  <ListItem style={{ marginBottom: "8px" }}>
                    <MKTypography variant="body2" color="text">
                      {dataOffers.assistance_info.module2.description1.liste2}
                    </MKTypography>
                  </ListItem>
                  <Divider></Divider>
                  <ListItem style={{ marginBottom: "8px" }}>
                    <MKTypography variant="body2" color="text">
                      {dataOffers.assistance_info.module2.description1.liste3}
                    </MKTypography>
                  </ListItem>
                </List>
              </Collapse>
            </Fade>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Looks3 fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Nouvelles Technologies" />
              </ListItemButton>
            </ListItem>

            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Fade cascade damping={0.3}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Notre responsabilités envers vous :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {dataOffers.assistance_info.module3.description1.liste1}
                        </Typography>
                        <Divider></Divider>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {dataOffers.assistance_info.module3.description1.liste2}
                        </Typography>
                        <Divider></Divider>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {dataOffers.assistance_info.module3.description1.liste3}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Fade>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <MKBox
                component="img"
                src={NewTechnology}
                alt="material"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AssistanceInfo;
