import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { scrollToComponent } from "./ScrollShelpers";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MKBox from "./MKBox";
import { Assistant, CloudCircle, Computer, Keyboard, Person2, Poll } from "@mui/icons-material";

const BoutonFlottant = () => {
  const [showButton, setShowButton] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 192) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const options = [
    {
      id: "sage_component",
      icon: <Person2 />,
      title: "Assistant Sage 100",
    },
    {
      id: "it_component",
      icon: <CloudCircle />,
      title: "Assistance Informatique",
    },
    {
      id: "av_component",
      icon: <Assistant />,
      title: "Assistance Virtuel",
    },
    {
      id: "dev_component",
      icon: <Computer />,
      title: "Développement Informatique",
    },
    {
      id: "vba_component",
      icon: <Poll />,
      title: "VBA Excel",
    },
    {
      id: "data_component",
      icon: <Keyboard />,
      title: "Saisie Données",
    },
  ];

  return (
    <div className="fixed bottom-10 right-10">
      <AnimatePresence>
        {showButton && (
          <motion.div whileTap={{ scale: 1, rotate: 90 }}>
            <Fab
              color="primary"
              aria-label="add"
              className="text-white font-bold rounded-full"
              style={{ fontSize: "28px" }}
              onClick={toggleOptions}
            >
              <AddIcon />
            </Fab>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showOptions && (
          <MKBox
            className="absolute bottom-16 right-1 mb-3 flex flex-col space-y-2 text-right"
            sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}
          >
            {options.map((option) => (
              <Tooltip title={option.title} placement="left" key={option.id}>
                <button
                  className="bg-white shadow-lg px-4 py-2 rounded-full flex-1"
                  onClick={() => {
                    scrollToComponent(option.id);
                  }}
                >
                  {option.icon}
                </button>
              </Tooltip>
            ))}
          </MKBox>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BoutonFlottant;
