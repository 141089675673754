/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/desktop.png";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import { Fade, Slide } from "react-awesome-reveal";

function BuiltByDevelopers() {
  return (
    <Fade>
      <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={2}
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.7)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Fade cascade damping={0.5}>
                <MKTypography variant="h4" color="white" fontWeight="bold">
                  Assistance Personnelle
                </MKTypography>
                <MKTypography variant="h1" color="white" mb={1}>
                  Offre Personnalisée
                </MKTypography>
                <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                  À travers notre service d'Assistance Personnelle et notre Offre Personnalisée,
                  nous nous engageons à fournir un soutien sur mesure dans plusieurs domaines clés
                  de l'informatique et de l'assistance, répondant ainsi aux exigences spécifiques de
                  chaque client.
                </MKTypography>
              </Fade>

              <br />
              <Slide direction="up" cascade duration={700}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    color="info"
                    icon="computer"
                    title="Développement Informatique"
                    description="En ce qui concerne le Développement Informatique, nous proposons des solutions personnalisées pour créer, maintenir et améliorer les logiciels et les applications selon vos besoins spécifiques. Que vous recherchiez une application web, un logiciel sur mesure ou une intégration de système complexe, notre équipe d'experts est prête à relever le défi."
                  />
                </Grid>
              </Slide>
              <br />
              <Slide direction="up" cascade duration={700}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    color="info"
                    icon="poll"
                    title="VBA Excel"
                    description="Pour ceux qui utilisent Excel comme outil essentiel dans leur travail, notre Offre de Services en Développement VBA pour Excel est là pour automatiser les tâches, créer des macros personnalisées et améliorer l'efficacité de vos feuilles de calcul. Nous pouvons développer des solutions VBA sur mesure pour répondre à vos besoins spécifiques et optimiser votre flux de travail Excel."
                  />
                </Grid>
              </Slide>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Slide direction="up" cascade duration={500}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    variant="gradient"
                    color="info"
                    icon="analytics"
                    title="Sage 100"
                    description="Pour les utilisateurs du logiciel SAGE 100, notre équipe qualifiée offre une assistance technique et opérationnelle complète, garantissant une utilisation fluide et efficace de cette plateforme de gestion. Nous sommes là pour résoudre les problèmes, répondre aux questions et optimiser l'expérience utilisateur."
                  />
                </Grid>
              </Slide>
              <br />
              <Slide direction="up" cascade duration={700}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    color="info"
                    icon="cloudcircle"
                    title="Assistance Informatique"
                    description="Dans le domaine de l'assistance informatique, nous sommes votre partenaire de confiance pour résoudre toute une gamme de problèmes informatiques, que ce soit des questions de configuration, des problèmes de réseau, des pannes matérielles ou des conseils généraux sur l'utilisation des technologies de l'information."
                  />
                </Grid>
              </Slide>
              <br />
              <Slide direction="up" cascade duration={700}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    color="info"
                    icon="assistant"
                    title="Assistant Virtuel"
                    description="Notre service d'Assistant Virtuel vous offre une solution flexible et polyvalente pour gérer diverses tâches administratives et informatiques à distance. Que ce soit la gestion de votre emploi du temps, la coordination de vos rendez-vous, la rédaction de documents ou la recherche en ligne, notre équipe est là pour vous libérer du temps et vous aider à rester concentré sur vos priorités."
                  />
                </Grid>
              </Slide>
              <br />
              <Slide direction="up" cascade duration={700}>
                <Grid item xs={12} lg={12}>
                  <FilledInfoCard
                    color="info"
                    icon="keyboard"
                    title="Saisie de donnée"
                    description="Notre Offre de Services en Saisie de Données vous assure une précision et une efficacité maximales dans la gestion de vos données. Que ce soit la saisie de données volumineuses, leur nettoyage, leur organisation ou leur analyse, notre équipe est formée pour garantir que vos données sont gérées de manière professionnelle et sécurisée."
                  />
                </Grid>
              </Slide>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </Fade>
  );
}

export default BuiltByDevelopers;
