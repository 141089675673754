// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-footer.png";
import { LinkedIn } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Kairos MerakIT",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/in/kairos-merakit/",
    },
  ],
  menus: [
    {
      name: "companie",
      items: [{ name: "A propos", href: "/pages/landing-pages/about-us" }],
    },
    {
      name: "support & aide",
      items: [{ name: "nous contactez", href: "/pages/landing-pages/contact-us" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Tous droits réservés. Copyright <b>&copy; {date}</b> Kairos MerakIT
    </MKTypography>
  ),
};
