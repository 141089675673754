/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//Image
import consultant from "assets/images/desc/sage.gif";
import assistance_info from "assets/images/desc/assistance_info.png";
import virtual_assistant from "assets/images/desc/virtual_assistant.png";
import dev_info from "assets/images/desc/dev_info.png";
import vba_excel from "assets/images/desc/vba.png";
import data_entry from "assets/images/desc/data_entry.png";
import Sage from "../offres/Sage";
import AssistanceInfo from "../offres/AssistanceInfo";
import AssistantVirtuel from "../offres/AssistantVirtuel";
import DevInformatique from "../offres/DevInformatique";
import VbaExcel from "../offres/VbaExcel";
import DataEntry from "../offres/DataEntry";

export default [
  {
    img_desc: `${consultant}`,
    content: <Sage />,
  },
  {
    img_desc: `${assistance_info}`,
    content: <AssistanceInfo />,
  },
  {
    img_desc: `${virtual_assistant}`,
    content: <AssistantVirtuel />,
  },
  {
    img_desc: `${dev_info}`,
    content: <DevInformatique />,
  },
  {
    img_desc: `${vba_excel}`,
    content: <VbaExcel />,
  },
  {
    img_desc: `${data_entry}`,
    content: <DataEntry />,
  },
];
