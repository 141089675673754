const dataOffers = {
  assistance_info: {
    titre: "Assistance Informatique",
    description:
      "Notre équipe d'experts en assistance informatique vous offre des solutions sur mesure pour résoudre les problèmes, configurer le matériel et les logiciels, assurer la sécurité des données et mettre en œuvre de nouvelles technologies.",
    module1: {
      description1:
        "Configuration méticuleuse de votre matériel informatique pour garantir des performances optimales et une interconnectivité fluide.",
      description2:
        "Installation, personnalisation et mise en réseau de logiciels pour assurer un environnement logiciel stable, performant et sécurisé.",
    },
    module2: {
      description1: {
        liste1: "Élaborer et dispenser des programmes de sensibilisation à la sécurité.",
        liste2: "Assurer une veille technologique constante.",
        liste3:
          "Collaborer avec les équipes de développement pour intégrer des mesures de sécurité dès la conception.",
      },
    },
    module3: {
      description1: {
        liste1:
          "Effectuer une veille technologique constante pour identifier les tendances émergentes.",
        liste2:
          "Collaborer étroitement avec les équipes internes pour comprendre les besoins opérationnels.",
        liste3:
          "Évaluer la faisabilité technique des projets en tenant compte de la sécurité et de la scalabilité.",
      },
    },
  },
};

export default dataOffers;
