/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/macbook.png";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import waiting from "assets/images/loader/waiting.svg";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function Newsletter() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  // Define validateForm function before sendEmail
  const validateForm = () => {
    if (!isValidEmail(email)) {
      toast.error("Veuillez saisir une adresse email valide.", {
        duration: 3500,
        position: "top-center",
      });
      setErrorMessage("Veuillez saisir une adresse email valide.");
      return false;
    }

    return true;
  };

  // Define sendEmail function
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    emailjs.init(process.env.REACT_APP_USER_ID);
    // Utiliser emailjs pour envoyer l'e-mail
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target)
      .then(
        function (response) {
          console.log("Email sent!", response.status, response.text);
          setIsSubmitting(false); // Signaler que l'envoi est terminé
          setIsSubmitted(true);
          swal({
            title: "Envoyé",
            text: "Email bien enregistrer",
            icon: "success",
            button: "Fermer",
          });
        },
        function (error) {
          console.log("Email failed to send.", error);
          toast.error("Veuillez saisir une adresse email valide.", {
            duration: 3500,
            position: "top-center",
          });
          setIsSubmitting(false); // Signaler l'échec de l'envoi
          setErrorMessage("Failed to send email. Please try again later.");
        }
      );

    // Réinitialiser le formulaire après l'envoi
    e.target.reset();
  };

  // Define isValidEmail function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <MKBox component="section" pt={6} my={6}>
      <Toaster />
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Soyez le premier à recevoir les nouvelles</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Être le premier à recevoir les nouvelles de notre entreprise est une avantage pour
              améliorer l'expérience entre nous
            </MKTypography>

            <form onSubmit={sendEmail}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <MKInput
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    label="Saisir votre email ..."
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  {isSubmitted ? (
                    <MKBox
                      variant="gradient"
                      bgColor="success"
                      coloredShadow="#efefef"
                      borderRadius="md"
                      onClick={() =>
                        swal({
                          title: "Envoye Message",
                          text: "Message Bien envoyé",
                          icon: "success",
                        })
                      }
                      mx={2}
                      className="text-center"
                    ></MKBox>
                  ) : (
                    <MKButton
                      type="submit"
                      disabled={isSubmitting}
                      variant="gradient"
                      color={isSubmitting ? "" : "info"}
                      sx={{ height: "100%" }}
                    >
                      {isSubmitting ? <img src={waiting} alt="waiting" /> : "Abonnez"}
                    </MKButton>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
