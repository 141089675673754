import React from "react";
import { Divider, Grid, ListItem, ListItemText, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Fade, Zoom } from "react-awesome-reveal";

// Image
import AssitanceVir from "assets/images/offers/assistantVirtuel.svg";
import AssitanceSecurity from "assets/images/offers/AssistSecurity.svg";

// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AssistantVirtuel() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container id="av_component" style={{ textAlign: "center" }}>
        <Zoom>
          <Typography variant="h1" gutterBottom style={{ color: "#900C3F" }}>
            Assistant Virtuel
          </Typography>
        </Zoom>
      </Container>
      <Divider component="div" role="presentation" />
      <Container>
        <Grid container alignItems="center">
          {/* Ce que nous vous offrons */}
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <MKTypography variant="h3">Ce que nous vous offrons</MKTypography>
            <ul style={{ listStyle: "circle" }}>
              {/* Listes */}
              {assistantOffers.map((offer, index) => (
                <Fade key={index} direction={index % 2 === 0 ? "left" : "right"}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary={offer.title} secondary={offer.description} />
                  </ListItem>
                </Fade>
              ))}
            </ul>
          </Grid>
          {/* Image */}
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <img
                src={AssitanceVir}
                alt="assistant-virtuel"
                style={{ borderRadius: "8px", width: "100%", position: "relative", zIndex: 1 }}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
      {/* Autre contenu */}
      <Container>
        <Grid container alignItems="center">
          {/* Image */}
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="left">
              <img
                src={AssitanceSecurity}
                alt="assistance-securite"
                style={{ borderRadius: "8px", width: "100%", position: "relative", zIndex: 1 }}
              />
            </Fade>
          </Grid>
          {/* Autre ce que nous vous offrons */}
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <ul style={{ listStyle: "circle" }}>
              {assistantOffers2.map((offer, index) => (
                <Fade key={index} direction={index % 2 === 0 ? "right" : "left"}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary={offer.title} secondary={offer.description} />
                  </ListItem>
                </Fade>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AssistantVirtuel;

// Offres pour l'assistant virtuel
const assistantOffers = [
  {
    title: "Gestion Administrative Simplifiée :",
    description:
      "Confiez les tâches administratives à notre assistant virtuel pour gagner du temps et vous concentrer sur les aspects essentiels de votre entreprise.",
  },
  {
    title: "Agenda Optimisé :",
    description:
      "Laissez notre assistant virtuel gérer votre agenda, planifier vos réunions et organiser vos rendez-vous de manière efficace, afin que vous puissiez rester concentré sur vos objectifs.",
  },
  {
    title: "Réponse aux E-mails et au Support Client :",
    description:
      "Nous prendrons en charge la gestion de vos e-mails et assure un support client réactif, vous permettant ainsi de maintenir une communication fluide avec vos clients.",
  },
];

const assistantOffers2 = [
  {
    title: "Recherche et Collecte d'Informations :",
    description:
      "Bénéficiez de l'aide de notre assistant virtuel pour effectuer des recherches approfondies, collecter des données pertinentes et vous fournir des informations précieuses pour prendre des décisions éclairées.",
  },
  {
    title: "Gestion de Projet et Suivi des Tâches :",
    description:
      "De la planification à la réalisation, notre assistant virtuel vous aide à gérer efficacement vos projets en suivant les tâches, en respectant les échéances et en maintenant la cohésion de l'équipe.",
  },
];
