/* eslint-disable no-undef */
import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { Fade, Zoom } from "react-awesome-reveal";
import emailjs from "emailjs-com";
import swal from "sweetalert";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/contact/contact-us.jfif";
import waiting from "assets/images/loader/waiting.svg";
import { Navigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function ContactUs() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [messEmail, setMessEmail] = useState("");

  emailjs.init(process.env.REACT_APP_USER_ID);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    const emailParams = {
      email: email,
      subject: object,
      from_name: name,
      message: messEmail,
    };

    emailjs
      .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, emailParams)
      .then(
        function (response) {
          console.log("Email sent!", response.status, response.text);
          setIsSubmitting(false);
          setIsSubmitted(true);
          clearFormFields();
          swal({
            title: "Envoyé",
            text: "Message bien envoyé",
            icon: "success",
            button: "Fermer",
          });
        },
        function (error) {
          console.log("Email failed to send.", error);
          setIsSubmitting(false);
          toast.error("L'envoi de l'e-mail a échoué. Veuillez réessayer plus tard.", {
            duration: 3500,
            position: "top-center",
          });
          setErrorMessage("L'envoi de l'e-mail a échoué. Veuillez réessayer plus tard.");
        }
      );
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    if (!name || !email || !messEmail || !object) {
      toast.error("Veuillez remplir tous les champs.", {
        duration: 3500,
        position: "top-center",
      });
      setErrorMessage("Veuillez remplir tous les champs");
      return false;
    }

    if (!isValidEmail(email)) {
      toast.error("Veuillez saisir une adresse email valide.", {
        duration: 3500,
        position: "top-center",
      });
      setErrorMessage("Veuillez saisir une adresse email valide.");
      return false;
    }

    return true;
  };

  const clearFormFields = () => {
    setName("");
    setEmail("");
    setObject("");
    setMessEmail("");
  };

  return (
    <>
      <Toaster />
      <MKBox position="absolute" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container style={{ marginTop: "4rem" }}>
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                      <Fade cascade damping={0.5} direction="up">
                        <MKTypography variant="h3" color="white" mb={1}>
                          Coordonnées
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                          Remplissez le formulaire, et notre équipe vous répondra dans les plus
                          brefs délais.
                        </MKTypography>
                        <MKBox display="flex" p={1}>
                          <MKTypography variant="button" color="white">
                            <i className="fas fa-phone" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                          >
                            (+261)0 34 35 597 28
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="white" p={1}>
                          <MKTypography variant="button" color="white">
                            <i className="fas fa-envelope" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                          >
                            contact@kairos-merakit.com
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="white" p={1}>
                          <MKTypography variant="button" color="white">
                            <i className="fas fa-map-marker-alt" />
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                          >
                            105, Ambohibao Antananarivo
                          </MKTypography>
                        </MKBox>
                        <MKBox mt={3}>
                          <MKTypography
                            component="a"
                            variant="body1"
                            color="white"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/kairos-merakit/"
                            mr={3}
                            style={{ marginLeft: "10px" }}
                          >
                            <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                          </MKTypography>
                        </MKBox>
                      </Fade>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <MKBox p={2}>
                    <Fade>
                      <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                        <MKTypography variant="h2" mb={1}>
                          Contactez-nous !
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                          Nous aimerions discuter avec vous.
                        </MKTypography>
                      </MKBox>
                    </Fade>
                    <MKBox
                      pt={0.5}
                      pb={3}
                      px={3}
                      component="form"
                      onSubmit={sendEmail}
                      method="post"
                      autoComplete="off"
                    >
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={6}>
                          <Zoom cascade damping={0.4}>
                            <MKInput
                              variant="standard"
                              label="Nom complet"
                              placeholder="Nom et Prénom"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              className="name"
                              name="name"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />{" "}
                          </Zoom>
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <Zoom cascade damping={0.4}>
                            <MKInput
                              variant="standard"
                              label="Adresse Email"
                              placeholder="Email"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              className="email"
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Zoom>
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <Zoom cascade damping={0.4}>
                            <MKInput
                              variant="standard"
                              label="Objet"
                              placeholder="Objet"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              className="object"
                              name="object"
                              onChange={(e) => setObject(e.target.value)}
                              required
                            />
                          </Zoom>
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <Zoom cascade damping={0.4}>
                            <MKInput
                              variant="standard"
                              label="Votre message"
                              placeholder="Message, nom de l'entreprise et degrè d'urgence de votre demande"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              multiline
                              rows={12}
                              className="messages"
                              name="messEmail"
                              onChange={(e) => setMessEmail(e.target.value)}
                              required
                            />
                          </Zoom>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        {errorMessage && (
                          <MKBox
                            variant="gradient"
                            bgColor="danger"
                            coloredShadow="#efefef"
                            borderRadius="md"
                            p={2}
                            mx={2}
                            mt={-1}
                          >
                            <MKTypography variant="p" color="white">
                              {errorMessage}
                            </MKTypography>
                          </MKBox>
                        )}
                        {isSubmitted ? (
                          <MKBox
                            variant="gradient"
                            bgColor="success"
                            coloredShadow="#efefef"
                            borderRadius="md"
                            onClick={() =>
                              swal({
                                title: "Envoye Message",
                                text: "Message Bien envoyé",
                                icon: "success",
                              })
                            }
                            p={2}
                            mx={2}
                            mt={-1}
                          >
                            <MKTypography variant="p" color="white">
                              Your message has been sent!
                            </MKTypography>
                            <Navigate to="/" />
                          </MKBox>
                        ) : (
                          <MKButton
                            type="submit"
                            variant="gradient"
                            color={isSubmitting ? "" : "info"}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? <img src={waiting} alt="waiting" /> : "Envoyez message"}
                          </MKButton>
                        )}
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
