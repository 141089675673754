/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import PhotoTech from "assets/images/Technology.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="information"
                    title="INFORMATIONS"
                    description="Notre entreprise utilise divers outils pour que les demandes des clients soient traitées de manière efficace et que leur expérience soit la meilleure possible. Parmi ces outils, nous mettons en œuvre des systèmes de gestion des tickets pour assurer un suivi précis et rapide des demandes entrantes. De plus, nous utilisons des plateformes de communication en temps réel, telles que des chats en direct, des e-mails et des numéros de téléphone dédiés, afin de permettre à nos clients de nous contacter facilement et d'obtenir des réponses rapidement."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={PhotoTech}
              title="Les outils que nous utilisons"
              description="Notre entreprise utilise divers outils pour que les demandes des clients soit le meilleur"
              action={{
                type: "internal",
                route: "",
                color: "info",
                label: "Consulter",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
