/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
// import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.png";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import MKButton from "components/MKButton";
import Bounce, { Fade, Zoom } from "react-awesome-reveal";
import BoutonFlottant from "components/FloatButton";

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Fade>
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} md="10" lg={10} justifyContent="center" mx="auto">
              <Fade cascade damping={0.2}>
                <MKTypography
                  variant="h1"
                  color="white"
                  mt={4}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Kairos MerakIT{" "}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="white"
                  textAlign="center"
                  px={{ xs: 6, lg: 12 }}
                  mt={1}
                >
                  Expert dans le conseil et l'assistance informatique, nous sommes dédiée à fournir
                  des solutions sur mesure afin d'aider les entreprises et les particuliers à
                  réaliser leurs objectifs
                </MKTypography>
              </Fade>
            </Grid>
          </Container>
        </MKBox>
      </Fade>
      <Card
        sx={{
          p: 1,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <DesignBlocks />
        {/* <Pages /> */}
        <Container sx={{ mt: 3 }}>
          <BuiltByDevelopers />
        </Container>
        <Testimonials />
        <MKBox pt={8} pb={6}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Merci pour votre confiance
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Votre satisfaction est notre principale préoccupation
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.linkedin.com/in/kairos-merakit/"
                  target="_blank"
                  color="linkedin"
                  size="medium"
                >
                  <Icon>linkedin</Icon>
                  <Zoom>
                    <MKTypography variant="p" color="white">
                      &nbsp;&nbsp;LinkedIn
                    </MKTypography>
                  </Zoom>
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        sx={{ mx: "auto", textAlign: "center" }}
        mt={5}
      >
        <Bounce duration={900}>
          <MKButton
            component={Link}
            to="/pages/landing-pages/contact-us"
            variant="outlined"
            target="_blank"
            rel="noreferrer"
            size="large"
            color="dark"
            style={{ textAlign: "center" }}
            className="hover:ring-cyan-950 ring-1"
          >
            Demandez un devis
          </MKButton>
        </Bounce>
      </Grid>
      <BoutonFlottant />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
