/* eslint-disable react/no-unescaped-entities */
// @mui material components
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import offers_sage from "./offers_sage";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";

function Sage() {
  const [openAccordion, setOpenAccordion] = useState({});

  // Function to toggle the state of a specific accordion item
  const toggleAccordion = (id) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const listes = offers_sage.map(({ id, intitule, description }) => (
    <MKBox display="flex" alignItems="center" p={2} key={id}>
      <Fade cascade damping={0.2}>
        <MKBox
          variant="gradient"
          bgColor="primary"
          color="white"
          coloredShadow="info"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="xl"
          className="mr-2"
        >
          <CheckCircleOutlineOutlined />
        </MKBox>
        <MKTypography
          variant="body2"
          color="text"
          pl={2}
          onClick={() => toggleAccordion(id)}
          className="rounded-3xl ring-1 ring-gray-200 shadow-lg hover:ring-pink-600 hover:ring-2 py-2 px-6 hover:cursor-pointer"
        >
          <b>
            {intitule.split(" ").map((word, index) =>
              index > 0 && index % 8 === 0 ? (
                <React.Fragment key={index}>
                  <br />
                  {word}
                </React.Fragment>
              ) : (
                word + " "
              )
            )}
          </b>
          <br />
          <span>{openAccordion[id] && description}</span>
        </MKTypography>
      </Fade>
    </MKBox>
  ));
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container id="sage_component">
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <Fade cascade damping={0.1}>
              <MKTypography variant="h3" my={1}>
                Assistant SAGE 100
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Nos équipes d'experts chevronnés en Sage 100, hautement compétents et efficaces,
                sont à l'écoute tant des entreprises que des particuliers, prêtes à intervenir dès
                que nécessaire.
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Nous vous accompagnons dans divers projets liés à Sage 100, qu'il s'agisse de
                déploiements, de configurations, d'assistance personnalisée ou quotidienne, ainsi
                que de sous-traitance de certaines de vos activités ou de résolution de problèmes.
                Nous sommes ouverts à toutes propositions et nous engageons à fournir un service de
                qualité qui répond à vos besoins spécifiques.
              </MKTypography>

              {/* <MKTypography
                component="a"
                href="https://youtu.be/hiVTfaMenx4"
                target="_blank"
                rel="noreferrer"
                variant="body2"
                color="info"
                fontWeight="regular"
              >
                Visionnez la démonstration
              </MKTypography> */}
              <MKTypography
                component={Link}
                href="/pages/landing-pages/contact-us"
                target="_blank"
                rel="noreferrer"
                variant="body2"
                color="white"
                fontWeight="regular"
                className="py-2 px-6 shadow-xl bg-blue-600 w-full"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1.5rem",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              >
                Demandez un devis
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </Fade>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <MKBox xs={12} style={{ margin: "0.5rem" }}>
              <MKTypography variant="h4" py={2} color="primary">
                Découvrez nos offres d'assistance
              </MKTypography>
            </MKBox>
            <Stack>{listes}</Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Sage;
