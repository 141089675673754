export default [
  {
    id: 1,
    intitule: "Formation personnalisée",
    description:
      "Des sessions de formation adaptées à vos besoins spécifiques pour vous aider à maîtriser pleinement les fonctionnalités de Sage.",
  },
  {
    id: 2,
    intitule: "Support technique 5j/7",
    description:
      "Une équipe d'experts disponibles à tout moment pour répondre à vos questions et résoudre vos problèmes techniques.",
  },
  {
    id: 3,
    intitule: "Assistance à l'implémentation",
    description:
      "Un accompagnement professionnel pour déployer efficacement les solutions Sage dans votre entreprise.",
  },
  {
    id: 4,
    intitule: "Analyse et conseils personnalisés",
    description:
      "Des conseils stratégiques et des analyses approfondies pour maximiser l'utilisation de Sage et optimiser les performances de votre entreprise.",
  },
  {
    id: 5,
    intitule: "Service client dédié",
    description:
      "Un service clientèle dédié pour traiter rapidement vos demandes et vous fournir un soutien personnalisé.",
  },
];
