/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
// import madaDistribution from "assets/images/logos/customers/mada-distribution.svg";
// import sahaza from "assets/images/logos/customers/sahaza.svg";
// import chicky from "assets/images/logos/customers/chicky.svg";
// import falisoa from "assets/images/logos/customers/falisoa.svg";
import { Fade, Zoom } from "react-awesome-reveal";
import TimeAgo from "./TimeAgo";
import { FormatQuote } from "@mui/icons-material";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={10}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <FormatQuote fontSize="large" />
          <Fade cascade damping={0.3}>
            <MKTypography variant="h3">
              Nous sommes des{" "}
              <span className="text-red-500 italic font-bold">
                partenaires informatiques de confiance
              </span>{" "}
              pour des dizaines d'entreprises et de particuliers à travers le monde. Notre{" "}
              <span className="text-red-500 italic">engagement</span> principal est de fournir des{" "}
              <span className="text-red-500 italic h1">solutions de haute qualité</span> qui
              répondent aux <span className="text-red-500">besoins uniques</span> de nos clients,
              œuvrant ainsi dans la livraison de{" "}
              <span className="text-pink-600 italic h1">résultats exceptionnels.</span>
            </MKTypography>

            <FormatQuote fontSize="large" />
          </Fade>
        </Grid>
        {/* <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={madaDistribution} alt="Mada" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={falisoa} alt="falisoa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={sahaza} alt="sahaza" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={chicky} alt="chicky" width="100%" opacity={0.6} />
          </Grid>
        </Grid> */}
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={1} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <Zoom>
              <DefaultReviewCard
                name="Fetraniaina Fidison"
                date={<TimeAgo date="01/08/2024" />}
                review="Avec Sage 100 Gestion commerciale, les utilisateurs bénéficient d'une interface intuitive et conviviale qui facilite la prise en main du logiciel"
                rating={5}
              />
            </Zoom>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Zoom>
              <DefaultReviewCard
                color="dark"
                name="Tsiory Nantenaina"
                date={<TimeAgo date="01/18/2024" />}
                review="Ces solutions ont hautements personnalisables, ce qui permet à entreprise d'adapter à ces besoins spécifiques et de bénéficier d'une solution sur mesure."
                rating={4.5}
              />
            </Zoom>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Zoom>
              <DefaultReviewCard
                name="Gaëlle Fanirisoa"
                date={<TimeAgo date="02/06/2024" />}
                review="Grâce à Sage 100 Saisie de caisse décentralisée, les entreprises peuvent bénéficier d'une gestion simplifiée de leur trésorerie, d'une meilleure traçabilité des transactions et d'une prise de décision éclairée."
                rating={5}
              />
            </Zoom>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
