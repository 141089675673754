/* eslint-disable react/no-unescaped-entities */

import { Divider, ListItem, ListItemText, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

//Image
import dataEntryIllustration from "assets/images/offers/dataEntryIllustrate.png";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import bgdataEntry from "assets/images/dataEntry.png";

function DataEntry() {
  return (
    <MKBox component="section" style={{ padding: "0 1rem" }}>
      <Container
        id="data_component"
        display="flex"
        alignItems="center"
        my={2}
        component="section"
        py={{ xs: 3, md: 12 }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.7),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgdataEntry})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
          borderRadius: "1rem",
          padding: "1rem",
          margin: "2rem 0",
        }}
      >
        <Zoom>
          <Typography variant="h1" gutterBottom style={{ color: "#ffffff", textAlign: "center" }}>
            Offre de Services en Saisie de Données
          </Typography>
        </Zoom>
      </Container>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <MKTypography variant="h2">Services proposés :</MKTypography>

            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Zoom cascade damping={0.1}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Saisie de Données Précise :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Notre équipe expérimentée se chargera de la saisie rapide et précise de
                          vos données, que ce soit à partir de documents papier, de fichiers
                          numériques ou d'autres sources.
                        </Typography>
                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Conversion de Données :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Si vous avez besoin de convertir des données d'un format à un autre, nous
                          pouvons vous aider à transférer vos informations en toute sécurité et sans
                          perte.
                        </Typography>
                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Saisie de Formulaires :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Nous prenons en charge la saisie de formulaires, questionnaires, enquêtes
                          et autres documents contenant des champs variés.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Saisie de Factures et de Comptes :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Gagnez du temps en externalisant la saisie de factures et de comptes
                          clients/fournisseurs pour une comptabilité précise et à jour.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Saisie de Données Volumineuses :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Que vous ayez une petite ou une grande quantité de données, notre équipe
                          est capable de gérer des volumes importants avec efficacité.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Zoom>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <MKBox
                component="img"
                src={dataEntryIllustration}
                alt="dataEntryIllustration"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DataEntry;
