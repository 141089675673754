/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { FactCheck } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { styled, useMediaQuery, useTheme } from "@mui/material";
function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  const Hover = {
    cursor: "pointer",
  };

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPolicy(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const UnderstandBtn = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  }));

  const [openPolicy, setOpenPolicy] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpenPolicy = () => {
    setOpenPolicy(true);
  };

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="6rem" mb={2} />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h4"
                  color="facebook"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={3} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          {/*LEGAL*/}
          <Grid item xs={6} md={3} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              Légal
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="p"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  style={Hover}
                  onClick={() => handleClickOpen("paper")}
                >
                  Termes et conditions
                </MKTypography>
                <Dialog
                  open={open}
                  fullScreen={fullScreen}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                      Termes et conditions{" "}
                      <CloseIcon
                        fontSize="medium"
                        sx={{ cursor: "pointer" }}
                        onClick={handleClose}
                      />
                    </MKBox>
                  </DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      <MKTypography variant="h3">Conditions Générales de Service</MKTypography>
                      <MKTypography variant="body2">
                        <MKBox component="ul" p={2} sx={{ listStyle: "number" }}>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Objet
                              </MKTypography>
                              Les présentes Conditions Générales de Service régissent la relation
                              contractuelle entre <b>Kairos MerakIT</b>, spécialisée dans le conseil
                              et l'assistance informatique (ci-après dénommée "le Prestataire"), et
                              ses clients, qu'ils soient des entreprises ou des particuliers
                              (ci-après dénommés "le Client"). Les services fournis par le
                              Prestataire sont conçus pour offrir des solutions sur mesure visant à
                              soutenir le Client dans la réalisation de ses objectifs.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Prestations
                              </MKTypography>
                              Le Prestataire s'engage à fournir des services de conseil et
                              d'assistance informatique selon les besoins spécifiques du Client. Les
                              prestations peuvent inclure, sans s'y limiter, la conception de
                              solutions personnalisées, la mise en place d'infrastructures
                              informatiques, la formation, la maintenance, et d'autres services
                              connexes.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Devis et Facturation
                              </MKTypography>
                              Avant le début des prestations, un devis détaillé sera fourni au
                              Client, comprenant la description des services, les tarifs
                              applicables, ainsi que les modalités de paiement. Les frais et
                              honoraires seront facturés conformément aux termes convenus
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Confidentialité
                              </MKTypography>
                              Les deux parties conviennent de traiter comme confidentielles toutes
                              les informations et données fournies par l'autre partie dans le cadre
                              de la prestation des services. Aucune information confidentielle ne
                              sera divulguée à des tiers sans le consentement écrit préalable de la
                              partie concernée.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Responsabilité
                              </MKTypography>
                              Le Prestataire s'engage à fournir des services de qualité conformes
                              aux meilleures pratiques de l'industrie. Cependant, le Prestataire ne
                              peut être tenu responsable des dommages directs ou indirects résultant
                              de l'utilisation des services fournis.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Durée et Résiliation
                              </MKTypography>
                              La durée de la prestation des services sera spécifiée dans le contrat
                              ou le devis. Chaque partie a le droit de résilier le contrat en cas de
                              manquement substantiel de l'autre partie aux obligations convenues.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Propriété Intellectuelle
                              </MKTypography>
                              Propriété Intellectuelle Tous les droits de propriété intellectuelle
                              découlant des travaux effectués dans le cadre des prestations
                              demeurent la propriété du Prestataire, sauf disposition contraire
                              expressément convenue.
                            </MKTypography>
                          </MKBox>
                          <MKBox component="li" lineHeight={1.25}>
                            <MKTypography variant="p">
                              <MKTypography variant="h6" color="primary">
                                Lois Applicables
                              </MKTypography>
                              Les présentes Conditions Générales de Service sont régies par les lois
                              en vigueur dans <u>Madagascar, ANTANANARIVO</u>. Tout litige découlant
                              de ces conditions sera soumis à la compétence exclusive des tribunaux
                              de ANTANANARIVO, Analamanga. En acceptant ces conditions, le Client
                              reconnaît avoir lu, compris et accepté l'ensemble des termes et
                              conditions énoncés ci-dessus.
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <UnderstandBtn
                      onClick={handleClose}
                      variant="contained"
                      style={{ color: "#fff", backgroundColor: "#A83DE6" }}
                      startIcon={<FactCheck />}
                    >
                      Compris !
                    </UnderstandBtn>
                  </DialogActions>
                </Dialog>
                <MKTypography
                  component="p"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  style={Hover}
                  onClick={handleClickOpenPolicy}
                >
                  Politiques de confidentialité
                </MKTypography>
                <Dialog
                  fullScreen={fullScreen}
                  open={openPolicy}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Politiques de confidentialité"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <MKTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                        <u>Dernière mise à jour</u> : <b>02-08-2023</b>
                      </MKTypography>
                      <MKTypography variant="p" fontWeight="regular">
                        La société Kairos MerakIT, spécialisée dans le conseil et l'assistance
                        informatique (ci-après dénommée "nous", "notre", "nos"), est engagée à
                        respecter et à protéger la confidentialité des informations personnelles de
                        nos clients et utilisateurs (ci-après dénommés "vous", "votre", "vos").
                        Cette politique de confidentialité vise à vous informer sur la manière dont
                        nous collectons, utilisons, partageons et protégeons vos données
                        personnelles dans le cadre de nos services de solutions sur mesure pour
                        aider les entreprises et les particuliers à atteindre leurs objectifs.
                      </MKTypography>
                      <MKTypography variant="p">
                        <MKTypography variant="h6" color="primary">
                          Collecte et Utilisation des Données Personnelles
                        </MKTypography>
                        Nous collectons des informations personnelles de différentes manières,
                        notamment lorsque vous utilisez notre site web, nos services ou que vous
                        entrez en contact avec nous. Les types d'informations que nous pouvons
                        collecter comprennent, mais ne sont pas limités à :
                      </MKTypography>{" "}
                      <MKBox component="ul" p={2} sx={{ listStyle: "circle" }}>
                        <MKBox component="li" lineHeight={1.25}>
                          <MKTypography variant="p">
                            Informations d'identification : nom, prénom, adresse, numéro de
                            téléphone, adresse e-mail.
                          </MKTypography>
                        </MKBox>
                        <MKBox component="li" lineHeight={1.25}>
                          <MKTypography variant="p">
                            Informations professionnelles : entreprise, poste, secteur d'activité.
                          </MKTypography>{" "}
                        </MKBox>
                      </MKBox>{" "}
                      <MKTypography variant="p">
                        Nous utilisons ces informations pour fournir nos services, personnaliser
                        votre expérience, communiquer avec vous, traiter vos demandes et améliorer
                        nos services.
                      </MKTypography>
                      <MKTypography variant="p">
                        <MKTypography variant="h6" color="primary">
                          Partage d'Informations
                        </MKTypography>
                        Nous ne partageons vos informations personnelles qu'avec des tiers dans les
                        circonstances suivantes :
                      </MKTypography>
                      <MKBox component="ul" p={2} sx={{ listStyle: "number" }}>
                        <MKBox component="li" lineHeight={1.25}>
                          <MKTypography variant="p">
                            Lorsque cela est nécessaire pour la prestation de nos services ou pour
                            répondre à vos demandes.
                          </MKTypography>
                        </MKBox>
                        <MKBox component="li" lineHeight={1.25}>
                          <MKTypography variant="p">
                            Lorsque cela est nécessaire pour la prestation de nos services ou pour
                            répondre à vos demandes. Lorsque requis par la loi ou en réponse à une
                            procédure légale.{" "}
                          </MKTypography>
                        </MKBox>
                        <MKBox component="li" lineHeight={1.25}>
                          <MKTypography variant="p">
                            Lorsque cela est nécessaire pour protéger nos droits, votre sécurité ou
                            celle d'autres personnes.{" "}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                      <MKTypography variant="h6" color="primary">
                        Sécurité des Données
                      </MKTypography>
                      <MKTypography variant="p">
                        Nous prenons des mesures techniques et organisationnelles pour sécuriser vos
                        données personnelles et prévenir tout accès non autorisé, perte ou
                        divulgation.
                      </MKTypography>
                      <MKTypography variant="p">
                        <MKTypography variant="h6" color="primary">
                          Vos Droits
                        </MKTypography>
                        Vous pouvez vous opposer au traitement de vos données personnelles. Pour
                        exercer vos droits ou pour toute question concernant cette politique de
                        confidentialité, veuillez nous contacter à{" "}
                        <a href="mailto:contact@kairos-merakit.com">contact@kairos-merakit.com</a>.
                      </MKTypography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Refuser
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                      Accepter
                    </Button>
                  </DialogActions>
                </Dialog>
              </MKBox>
            </MKBox>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
