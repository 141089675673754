/* eslint-disable react/no-unescaped-entities */

import { Divider, ListItem, ListItemText, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

//Image
import vbaExcel from "assets/images/offers/vba_excel.png";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import bgVbaExcel from "assets/images/vbaExcel.png";

function VbaExcel() {
  return (
    <MKBox component="section" style={{ padding: "0 1rem" }}>
      <Container
        id="vba_component"
        display="flex"
        alignItems="center"
        my={2}
        component="section"
        py={{ xs: 3, md: 12 }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.7),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgVbaExcel})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
          borderRadius: "1rem",
          padding: "1rem",
          margin: "2rem 0",
        }}
      >
        <Zoom>
          <Typography variant="h1" gutterBottom style={{ color: "#ffffff", textAlign: "center" }}>
            Offre de Services en Développement VBA pour Excel
          </Typography>
        </Zoom>
      </Container>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <MKTypography variant="h2">Services proposés :</MKTypography>

            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Zoom cascade damping={0.1}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Automatisation de Tâches Répétitives :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Création de macros VBA pour automatiser des tâches manuelles et
                          répétitives dans Microsoft Excel, ce qui vous permettra d'économiser du
                          temps et d'améliorer l'efficacité.
                        </Typography>
                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Personnalisation de Feuilles de Calcul :"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Personnalisation avancée de vos feuilles de calcul Excel, y compris la
                          création de formulaires interactifs, de tableaux de bord dynamiques et de
                          modèles personnalisés.
                        </Typography>
                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Analyse de Données : "
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          Développement de solutions VBA pour l'analyse de données complexes, y
                          compris la création de fonctions personnalisées, de graphiques avancés et
                          de rapports automatisés
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Zoom>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <MKBox
                component="img"
                src={vbaExcel}
                alt="vba_excel"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default VbaExcel;
