/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import Description_img from "assets/images/Description.png";
// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";
// import MKBadge from "components/MKBadge";
// import MKTypography from "components/MKTypography";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ExampleCard from "../components/ExampleCard";
import MKTypography from "components/MKTypography";
import { Fade } from "react-awesome-reveal";
import { scrollToComponent } from "components/ScrollShelpers";

function DesignBlocks() {
  const renderData = data.map(({ img_desc, content }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={img_desc}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ lg: 6 }}>
          <ExampleCard image={img_desc} style={{ borderRadius: "25px" }} />
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {content}
        </Grid>
      </Grid>
    </Grid>
  ));
  // Fonction pour faire défiler vers le composant "Sage"
  const handleScrollToSage = () => {
    scrollToComponent("sage_component");
  };

  // Fonction pour faire défiler vers le composant "IT"
  const handleScrollToIT = () => {
    scrollToComponent("it_component");
  };

  // Fonction pour faire défiler vers le composant "AV"
  const handleScrollToAV = () => {
    scrollToComponent("av_component");
  };

  // Fonction pour faire défiler vers le composant "Dev Info"
  const handleScrollToDevInfo = () => {
    scrollToComponent("dev_component");
  };

  // Fonction pour faire défiler vers le composant "VBA"
  const handleScrollToVBA = () => {
    scrollToComponent("vba_component");
  };

  // Fonction pour faire défiler vers le composant "VBA"
  const handleScrollToData = () => {
    scrollToComponent("data_component");
  };
  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          flexDirection="column"
          alignItems="center"
          marginTop="-4rem"
          sx={{ textAlign: "center", mx: "auto" }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Listes De Nos Offres"
            container
            sx={{ mb: 2 }}
          /> */}
          <MKBox position="relative">
            <Fade cascade damping={0.3}>
              <MKTypography variant="h2" fontWeight="bold" style={{ marginBottom: "2rem" }}>
                Nos offres disponibles
              </MKTypography>
            </Fade>
            <div className="flex flex-wrap justify-center gap-4 mt-4 mb-8 md:gap-8">
              <div
                onClick={handleScrollToSage}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-pink-500"
              >
                Assistance Sage 100
              </div>
              <div
                onClick={handleScrollToIT}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-amber-500"
              >
                Assistance Informatique
              </div>
              <div
                onClick={handleScrollToAV}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-cyan-500"
              >
                Assistant Virtuelle
              </div>
              <div
                onClick={handleScrollToDevInfo}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-yellow-500"
              >
                Développement Informatique
              </div>
              <div
                onClick={handleScrollToVBA}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-green-500"
              >
                Développement VBA pour Excel
              </div>
              <div
                onClick={handleScrollToData}
                className="hover:cursor-pointer px-6 py-2 text-base font-semibold rounded-full shadow-lg bg-white transform transition-transform duration-300 md:w-auto ring-1 hover:ring-2 ring-gray-300 hover:ring-purple-500"
              >
                Saisie De Données
              </div>
            </div>
            <MKBox
              component="img"
              sx={{ mt: 4 }}
              src={Description_img}
              alt="macbook"
              width="100%"
            />
          </MKBox>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
