/* eslint-disable react/no-unescaped-entities */

import { Divider, ListItem, ListItemText, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

//Image
import DevInfo from "assets/images/offers/devInfo.png";
import DataInfo from "assets/images/offers/dataSystem.png";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import bgImageInfo from "assets/images/devInfoImage.png";

// eslint-disable-next-line react/prop-types
const OfferItem = ({ primary, secondary }) => (
  <ListItem alignItems="flex-start">
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);

const DevInformatique = () => {
  const offers = [
    {
      title: "Développement d'Applications Web et Bureau :",
      description:
        "Création sur mesure d'applications web et bureau répondant aux besoins spécifiques de votre entreprise. Utilisation de technologies modernes pour offrir une expérience utilisateur exceptionnelle.",
      technologies: [
        "C#",
        "ASP.NET",
        ".NET Core",
        "Python",
        "JavaScript",
        "HTML/CSS",
        "VBA",
        "SQL",
        "PL/SQL",
      ],
    },
    {
      title: "Conception et Intégration d'Interfaces Utilisateur :",
      description:
        "Création d'interfaces utilisateur conviviales et esthétiques pour vos applications. Intégration de designs réactifs pour une expérience cohérente sur toutes les plateformes.",
    },
    {
      title: "Développement de Logiciels sur Mesure :",
      description:
        "Conception, développement et déploiement de solutions logicielles personnalisées pour automatiser vos processus commerciaux et améliorer l'efficacité opérationnelle.",
    },
    {
      title: "Mise en Œuvre de Bases de Données :",
      description:
        "Conception et gestion de bases de données robustes et évolutives pour stocker, gérer et analyser vos données de manière efficace.",
      technologies: ["SQL Server", "MongoDB", "MySQL", "Supabase", "Firebase"],
    },
    {
      title: "Intégration de Systèmes :",
      description:
        "Connexion transparente de vos systèmes et applications existantes pour permettre un échange fluide de données et une meilleure coordination.",
    },
    {
      title: "Maintenance et Support Technique :",
      description:
        "Fourniture d'un support continu pour garantir que vos systèmes et applications fonctionnent de manière optimale et sans interruption.",
    },
  ];
  return (
    <MKBox component="section" style={{ padding: "0 1rem" }}>
      <Container
        id="dev_component"
        display="flex"
        alignItems="center"
        my={2}
        component="section"
        py={{ xs: 3, md: 12 }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImageInfo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
          borderRadius: "1rem",
          padding: "1rem",
          margin: "2rem 0",
        }}
      >
        <Zoom>
          <Typography variant="h1" gutterBottom style={{ color: "#ffffff", textAlign: "center" }}>
            Développement Informatique
          </Typography>
        </Zoom>
      </Container>

      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <MKTypography variant="h2">Nos offres de services</MKTypography>
            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Zoom cascade damping={0.1}>
                {offers.slice(0, 3).map((offer, index) => (
                  <OfferItem
                    key={index}
                    primary={offer.title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {offer.description}
                        </Typography>
                        {offer.technologies && (
                          <div className="mt-4">
                            {offer.technologies.map((tech, index) => (
                              <span
                                key={index}
                                className="hover:bg-blue-600/5 inline-flex mx-1 mt-1  items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-gray-800 text-gray-800 dark:border-gray-200 dark:text-white"
                              >
                                {tech}
                              </span>
                            ))}
                          </div>
                        )}

                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                ))}
              </Zoom>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="right">
              <MKBox
                component="img"
                src={DevInfo}
                alt="dev_info"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Fade direction="left">
              <MKBox
                component="img"
                src={DataInfo}
                alt="material"
                borderRadius="lg"
                width="100%"
                position="relative"
                zIndex={1}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} lg={6} style={{ padding: "0.4rem" }}>
            <MKBox component="ul" sx={{ listStyle: "circle" }}>
              <Zoom cascade damping={0.1}>
                {offers.slice(3).map((offer, index) => (
                  <OfferItem
                    key={index}
                    primary={offer.title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {offer.description}
                        </Typography>
                        {offer.technologies && (
                          <div className="mt-4">
                            {offer.technologies.map((tech, index) => (
                              <span
                                key={index}
                                className="hover:bg-blue-600/5 inline-flex mx-1 mt-1  items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-gray-800 text-gray-800 dark:border-gray-200 dark:text-white"
                              >
                                {tech}
                              </span>
                            ))}
                          </div>
                        )}
                        <Divider></Divider>
                      </React.Fragment>
                    }
                  />
                ))}
              </Zoom>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default DevInformatique;
